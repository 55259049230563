import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6404ad85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-info" }
const _hoisted_2 = {
  key: 0,
  class: "image-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      ($props.showAvatar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              style: _normalizeStyle({
          backgroundImage: `url(${$props.user?.UserProfile?.ProfilePicture})`,
        }),
              class: "user-image"
            }, null, 4 /* STYLE */)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: "user-name",
        onClick: $setup.goToProfileSettings
      }, _toDisplayString($props.user?.UserProfile.FirstName) + " " + _toDisplayString($props.user?.UserProfile.LastName), 1 /* TEXT */)
    ]),
    _createVNode($setup["DxList"], {
      ref: "userInfoListRef",
      items: $props.menuItems,
      onItemClick: $setup.onItemClick,
      "element-attr": {
      class: 'user-info-list',
    }
    }, null, 8 /* PROPS */, ["items"])
  ], 64 /* STABLE_FRAGMENT */))
}