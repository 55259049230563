import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c63dd66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-panel" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.menuMode === 'context')
      ? (_openBlock(), _createBlock($setup["DxDropDownButton"], {
          key: 0,
          "styling-mode": "text",
          icon: "preferences",
          "show-arrow-icon": false,
          "element-attr": {
        class: 'user-button',
      },
          "drop-down-options": {
        width: 'auto',
      },
          "drop-down-content-template": "dropDownContentTemplate",
          onContentReady: $setup.handleDropDownButtonContentReady
        }, {
          dropDownContentTemplate: _withCtx(({}) => [
            _createVNode($setup["UserMenuSection"], {
              "menu-items": $props.menuItems,
              user: $props.user,
              "show-avatar": false,
              ref: "userMenuSection"
            }, null, 8 /* PROPS */, ["menu-items", "user"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($props.menuMode === 'list')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["UserMenuSection"], {
            "menu-items": $props.menuItems,
            user: $props.user,
            "show-avatar": true
          }, null, 8 /* PROPS */, ["menu-items", "user"])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}