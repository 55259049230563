export enum UserRole {
  IsAdmin = -1,
  Root = 0,

  OrderManagement = 1,
  OrderManagementViewPII = 92,
  OrderManagementDeletePII = 425,

  OrdersEditShippingAddress = 398,

  CustomerOrders = 61,
  CustomerOrdersCanExport = 406,
  CustomerOrdersGetFromMarketplace = 100,
  CustomerOrdersDeleteAttachment = 385,
  CustomerOrdersSingleSearch = 387,
  CustomerOrdersViewTotalCount = 400,
  CustomerOrdersViewTotalSum = 401,
  CustomerOrdersCanStatistics = 119,
  CustomerOrdersShowFilterCount = 81,
  CustomerOrdersShowFilter = 82,
  CustomerOrdersLimit = 89,
  CustomerOrdersCanSpreadsheetExport = 41,
  CustomerOrdersAllowReplaceArticleOnWritten = 147,
  CustomerOrdersAllowDragAndDrop = 436,

  CustomerOrderNew = 137,
  CustomerOrderNewInvoice = 298,
  CustomerOrderNewTaken = 299,
  CustomerOrderNewOffer = 300,
  CustomerOrderNewConfirmation = 301,
  CustomerOrderNewDeliveryNote = 302,
  CustomerOrderNewReplacement = 391,
  CustomerOrderNewCredit = 303,
  CustomerOrderNewRetoure = 304,

  CustomerOrderDelete = 139,
  CustomerOrderDeleteInvoice = 305,
  CustomerOrderDeleteTaken = 306,
  CustomerOrderDeleteOffer = 307,
  CustomerOrderDeleteConfirmation = 308,
  CustomerOrderDeleteDeliveryNote = 309,
  CustomerOrderDeleteReplacement = 392,
  CustomerOrderDeleteCredit = 310,
  CustomerOrderDeleteRetoure = 311,

  CustomerOrdersWrite = 50,
  CustomerOrderWriteInvoice = 312,
  CustomerOrderWriteTaken = 313,
  CustomerOrderWriteOffer = 314,
  CustomerOrderWriteConfirmation = 315,
  CustomerOrderWriteDeliveryNote = 316,
  CustomerOrderWriteReplacement = 393,
  CustomerOrderWriteCredit = 317,
  CustomerOrderWriteRetoure = 318,

  CustomerOrdersCancel = 220,
  CustomerOrderCancelInvoice = 333,
  CustomerOrderCancelTaken = 334,
  CustomerOrderCancelOffer = 335,
  CustomerOrderCancelConfirmation = 336,
  CustomerOrderCancelDeliveryNote = 337,
  CustomerOrderCancelReplacement = 394,
  CustomerOrderCancelCredit = 338,
  CustomerOrderCancelRetoure = 339,

  CustomerOrdersAddressValidate = 221,
  CustomerOrdersEmailSend = 177,
  CustomerOrdersFastChanges = 224,
  CustomerOrdersPfShipmentFeedback = 178,
  CustomerOrdersPrintDocuments = 179,
  CustomerOrdersProfitView = 284,
  CustomerOrdersProfitViewTotal = 384,

  CustomerOrdersItems = 213,
  CustomerOrdersItemsCanExport = 421,
  CustomerOrdersItemsDelete = 285,
  CustomerOrdersItemsFastChanges = 180,
  CustomerOrdersItemsImport = 200,

  CustomerOrdersPaymentsView = 214,
  CustomerOrdersPaymentsCanEdit = 210,
  CustomerOrdersPaymentsCanDelete = 211,
  CustomerOrdersFinStatementsView = 181,

  CustomerOrderView = 182,
  CustomerOrderViewInvoiceAdditional = 201,
  CustomerOrderViewInvoice = 319,
  CustomerOrderViewTaken = 320,
  CustomerOrderViewOffer = 321,
  CustomerOrderViewConfirmation = 322,
  CustomerOrderViewDeliveryNote = 323,
  CustomerOrderViewReplacement = 395,
  CustomerOrderViewCredit = 324,
  CustomerOrderViewRetoure = 325,

  CustomerOrderEdit = 183,
  CustomerOrderEditInvoice = 326,
  CustomerOrderEditTaken = 327,
  CustomerOrderEditOffer = 328,
  CustomerOrderEditConfirmation = 329,
  CustomerOrderEditDeliveryNote = 330,
  CustomerOrderEditReplacement = 396,
  CustomerOrderEditCredit = 331,
  CustomerOrderEditRetoure = 332,

  CustomerOrderPreviewExport = 33,
  CustomerOrderPreviewExportInvoice = 103,
  CustomerOrderPreviewExportTaken = 104,
  CustomerOrderPreviewExportOffer = 105,
  CustomerOrderPreviewExportConfirmation = 106,
  CustomerOrderPreviewExportDeliveryNote = 57,
  CustomerOrderPreviewExportReplacement = 102,
  CustomerOrderPreviewExportCredit = 108,
  CustomerOrderPreviewExportRetoure = 109,

  CustomerOrderArticleReport = 160,

  Customer = 159,
  CustomersCanExport = 408,
  CustomerCanStatistics = 133,
  CustomerEdit = 163,
  CustomerView = 164,
  CustomerDelete = 165,
  CustomerNew = 166,

  Shipments = 291,
  ShipmentsCanStatistics = 120,
  ShipmentsCanExport = 407,
  ShipmentNew = 161,
  ShipmentView = 289,
  ShipmentEdit = 203,
  ShipmentDelete = 107,
  ShipmentCancel = 388,
  ShipmentCreateWithoutInvoice = 389,

  Ordering = 2,
  SupplierOrders = 62,
  SupplierOrdersCanExport = 409,
  SupplierOrdersViewTotalCount = 402,
  SupplierOrdersViewTotalSum = 403,
  SupplierOrdersCanStatistics = 121,
  SupplierOrdersShowFilterCount = 83,
  SupplierOrdersShowFilter = 84,
  SupplierOrdersLimit = 90,
  SupplierOrdersCanSpreadsheetExport = 53,
  SupplierOrdersAddToStock = 124,
  SupplierOrdersAllowReplaceArticleOnWritten = 26,
  SupplierOrdersAllowDragAndDrop = 437,

  SupplierOrderView = 215,
  SupplierOrderViewInquiry = 340,
  SupplierOrderViewOrder = 341,
  SupplierOrderViewReceipt = 342,
  SupplierOrderViewInvoice = 343,
  SupplierOrderViewCancellation = 344,
  SupplierOrderViewOther = 345,
  SupplierOrderViewRetoure = 346,

  SupplierOrderItems = 382,
  SupplierOrderItemsCanExport = 420,

  SupplierOrdersWrite = 55,
  SupplierOrderWriteInquiry = 347,
  SupplierOrderWriteOrder = 348,
  SupplierOrderWriteReceipt = 349,
  SupplierOrderWriteInvoice = 350,
  SupplierOrderWriteCancellation = 351,
  SupplierOrderWriteOther = 352,
  SupplierOrderWriteRetoure = 353,

  SupplierOrdersCancel = 217,
  SupplierOrderCancelInquiry = 354,
  SupplierOrderCancelOrder = 355,
  SupplierOrderCancelReceipt = 356,
  SupplierOrderCancelInvoice = 357,
  SupplierOrderCancelCancellation = 358,
  SupplierOrderCancelOther = 359,
  SupplierOrderCancelRetoure = 360,

  SupplierOrdersPrintDocuments = 218,
  SupplierOrdersEmailSend = 63,
  SupplierOrderEdit = 151,
  SupplierOrderEditInquiry = 361,
  SupplierOrderEditOrder = 362,
  SupplierOrderEditReceipt = 363,
  SupplierOrderEditReceiptExtended = 445,
  SupplierOrderEditInvoice = 364,
  SupplierOrderEditCancellation = 365,
  SupplierOrderEditOther = 366,
  SupplierOrderEditRetoure = 367,

  SupplierOrdersFastChanges = 152,
  SupplierOrdersDelete = 216,
  SupplierOrderDeleteInquiry = 368,
  SupplierOrderDeleteOrder = 369,
  SupplierOrderDeleteReceipt = 370,
  SupplierOrderDeleteInvoice = 371,
  SupplierOrderDeleteCancellation = 372,
  SupplierOrderDeleteOther = 373,
  SupplierOrderDeleteRetoure = 374,

  SupplierOrdersNew = 155,
  SupplierOrderNewInquiry = 375,
  SupplierOrderNewOrder = 376,
  SupplierOrderNewReceipt = 377,
  SupplierOrderNewInvoice = 378,
  SupplierOrderNewCancellation = 379,
  SupplierOrderNewOther = 380,
  SupplierOrderNewRetoure = 381,

  SupplierOrdersItemsDelete = 208,

  SupplierOrderPreviewExport = 34,
  SupplierOrderPreviewExportInquiry = 184,
  SupplierOrderPreviewExportOrder = 35,
  SupplierOrderPreviewExportReceipt = 36,
  SupplierOrderPreviewExportInvoice = 134,
  SupplierOrderPreviewExportCancellation = 135,
  SupplierOrderPreviewExportOther = 37,
  SupplierOrderPreviewExportRetoure = 38,

  Suppliers = 64,
  SuppliersCanExport = 410,
  SupplierCanStatistics = 56,
  SupplierEdit = 154,
  SupplierView = 156,
  SupplierDelete = 157,
  SupplierNew = 65,

  OrderSuggestion = 129,

  OrderCart = 130,
  OrderCartView = 131,
  OrderCartNew = 204,
  OrderCartWrite = 162,
  OrderCartDelete = 167,
  OrderCartOrder = 209,
  OrderCartFastChanges = 158,

  Serialnumbers = 292,
  SerialnumbersAssign = 142,
  SerialnumbersAssignWithoutAll = 434,
  SerialnumbersCanExport = 424,
  SerialnumbersCanDelete = 294,
  SerialnumbersCanView = 193,
  SerialnumbersCanEdit = 126,

  SerialnumbersInput = 293,
  SerialnumbersInputMarkAsInvalid = 19,
  SerialnumbersInputCanNew = 20,
  SerialnumbersInputNewVolumenumber = 95,
  SerialnumbersInputCanView = 96,
  SerialnumbersInputCanEdit = 97,

  CustomerOrdersSerialnumbers = 98,
  CustomerOrdersSerialnumbersReplace = 138,
  CustomerOrdersSerialnumbersDelete = 23,
  CustomerOrdersSerialnumbersCanNew = 140,
  CustomerOrdersSerialnumbersAssignToOrder = 439,
  CustomerOrdersSerialnumbersDeleteAllRelations = 440,

  ClaimsSerialnumbers = 99,
  ClaimsSerialnumbersReplace = 21,
  ClaimsSerialnumbersDelete = 22,
  ClaimsSerialnumbersCanNew = 141,
  ClaimsSerialnumbersDeleteAllRelations = 441,

  Catalogue = 3,

  ArticleKeysUsages = 295,
  ArticleKeysUsagesCanExport = 423,

  Articles = 7,
  ArticlesCanExport = 199,
  ArticlesNew = 198,
  ArticlesView = 10,
  ArticlesEdit = 29,
  ArticlesActions = 13,
  ArticlesFastEdit = 15,
  ArticlesOrderingItemHistory = 110,
  ArticlesOrderItemHistory = 212,
  ArticlesClaimItemHistory = 30,
  ArticlesGetArticles = 16,
  ArticlesDelete = 18,
  ArticlesFastChanges = 11,
  ArticlesImport = 31,
  ArticlesSuppliersView = 112,
  ArticlesSuppliersEdit = 111,
  ArticlesPricesView = 113,
  ArticlesPricesCalc = 114,
  ArticlesPricesEdit = 115,
  ArticlesPricesProfitView = 116,
  ArticlesBpView = 117,
  ArticlesHistoryView = 32,
  ArticlesCanStatistics = 118,

  Management = 49,
  Statistics = 51,
  StatisticsEditProfile = 287,

  Offers = 8,
  OffersCanStatistics = 123,
  OffersCanExport = 411,
  OfferOpen = 79,
  OfferEdit = 80,
  OfferEnd = 144,
  OfferNew = 146,
  OfferRelist = 145,
  OfferDelete = 17,
  OfferSync = 87,
  OfferFastChanges = 88,

  Fin = 4,
  FinStatements = 66,
  FinStatementsCanStatistics = 58,
  FinStatementsCanExport = 412,
  FinStatementsView = 205,
  FinStatementsEdit = 202,
  FinStatementsDelete = 176,
  FinStatementsNew = 192,
  FinStatementsFastChanges = 443,
  FinStatementsExport = 444,

  FinAccountStatements = 78,
  FinAccountStatementsCanStatistics = 59,
  FinAccountStatementsCanExport = 413,
  FinAccountStatementsGetFromMarketplace = 170,
  FinAccountStatementsView = 168,
  FinAccountStatementsEdit = 169,
  FinAccountStatementsDelete = 222,
  FinAccountStatementsFastChanges = 442,

  FinOpenOrderCustomers = 172,
  FinOpenOrderCustomersCanExport = 414,
  FinOpenOrderCustomersView = 277,
  FinOpenOrderCustomersEdit = 93,
  FinOpenOrderCustomersDelete = 174,
  FinOpenOrderCustomersNew = 175,

  FinOpenOrderSuppliers = 68,
  FinOpenOrderSuppliersCanExport = 415,
  FinOpenOrderSuppliersView = 132,
  FinOpenOrderSuppliersEdit = 148,
  FinOpenOrderSuppliersDelete = 171,
  FinOpenOrderSuppliersNew = 173,

  StockManagement = 5,
  StockOutput = 189,
  StockOutputCanExport = 416,
  StockOutputCanOut = 190,
  StockOutputRefNrManuelly = 191,
  StockOutputWithoutItems = 296,
  StockOutputBackStore = 143,
  StockOutputBackStoreLocationOptional = 9,
  StockInventory = 297,

  StockImport = 288,
  StockItems = 69,
  StockItemsCanStatistics = 60,
  StockItemsCanExport = 417,
  StockItemsStockHistory = 188,
  StockItemsStockHistorySelectDisabledUsers = 427,
  StockInputDirect = 67,
  StockItemsDeleteSingle = 185,
  StockItemsDeleteSelection = 186,
  StockItemRestore = 206,
  StockItemRestoreArticle = 397,
  StockItemEditPosition = 187,

  Production = 70,
  SerialsCanStatistics = 136,
  SerialsCanExport = 422,

  Shipping = 6,
  ShippingShipmentDelete = 71,
  ShippingManuellyPost = 75,

  Claims = 73,
  ClaimsCanStatistics = 122,
  ClaimsCanExport = 418,
  ClaimsViewTotalCount = 404,
  ClaimsViewTotalSum = 405,
  ClaimsWrite = 194,
  ClaimsCancel = 195,
  ClaimsPrintDocuments = 196,
  ClaimsEmailSend = 197,
  ClaimView = 149,
  ClaimEdit = 150,
  ClaimDelete = 278,
  ClaimsFastChanges = 219,
  ClaimsNew = 72,
  ClaimsItemsDelete = 207,
  ClaimAddToStockSelection = 390,
  ClaimsShowFilterCount = 85,
  ClaimsShowFilter = 86,
  ClaimsLimit = 91,
  ClaimsCanSpreadsheetExport = 127,
  ClaimsAddUnstock = 125,
  ClaimsAllowReplaceArticleOnWritten = 14,
  ClaimsAllowDragAndDrop = 438,

  ClaimsPreviewExport = 101,

  ClaimItems = 386,
  ClaimItemsCanExport = 419,
  ClaimItemsCanSetBookingStatus = 426,

  Office = 74,
  OfficeTasks = 76,
  OfficeTasksSeeOtherTasks = 94,
  OfficeTasksDelete = 227,

  Common = 24,
  Protocol = 25,
  // = 226,
  ProtocolDelete = 39,
  ProtocolCancel = 40,
  ExecuteWorkflow = 27,
  SendeMail = 28,
  BusyByCanCloseForced = 399,
  FilterCreate = 428,
  FilterGroupCreate = 429,
  FilterRename = 430,
  FilterEdit = 431,
  FilterCopy = 432,
  FilterDelete = 433,

  Configuration = 12,

  ConfCommon = 269,
  ConfCommonSettings = 228,
  ConfPricipal = 229,
  ConfScheduler = 230,
  ConfUsers = 231,
  ConfUsersChangeUsername = 435,
  ConfUserGroups = 232,
  ConfCurrencies = 234,
  ConfRegions = 245,
  ConfCountries = 241,
  ConfLanguages = 240,
  ConfFtp = 238,
  ConfSmtp = 239,
  ConfWorkflow = 281,
  ConfCheckRules = 280,

  ConfOffice = 282,
  ConfOfficeMarks = 283,

  ConfLogistics = 271,
  ConfShippmentSettings = 242,
  ConfStocks = 243,
  ConfLocations = 46,

  ConfShippingTypes = 246,

  ConfManagement = 128,
  ConfStatistics = 48,

  ConfTemplates = 272,
  ConfTemplateSettings = 247,
  ConfTemplateLayout = 248,
  ConfTemplateDocs = 249,
  ConfTemplateEmail = 250,

  ConfCatalog = 47,
  ConfCatalogSetting = 54,
  ConfArticleAttributes = 52,
  ConfArticleCategories = 42,
  ConfArticleCollections = 43,
  ConfArticleGroups = 290,
  ConfUnits = 260,
  ConfSuppliers = 261,
  ConfBrands = 44,
  ConfManufacturers = 45,
  ConfCatalogMainProfiles = 244,
  ConfTesterTool = 286,
  ConfCatalogPricelist = 270,
  ConfCatalogPriceformula = 237,
  ConfOffersSettings = 383,

  ConfAccounts = 273,
  ConfMarketplaces = 251,
  ConfApiAccounts = 252,
  ConfBanking = 233,

  ConfSellOrders = 274,
  ConfSellCustomerSettings = 253,
  ConfSellMarks = 254,
  ConfSellTags = 255,
  ConfPaymentTypes = 256,
  ConfSellCommon = 258,
  ConfSellCollections = 259,
  ConfSellCustomerGroups = 225,
  ConfSellCustomerCollections = 223,

  ConfOrdering = 275,
  ConfOrderingSettings = 235,
  ConfOrderingMarks = 236,
  ConfOrderingTags = 279,
  ConfOrderingSupplierGroups = 267,
  ConfOrderingSupplierCollections = 268,

  ConfClaims = 276,
  ConfClaimSettings = 262,
  ConfClaimStatuses = 263,
  ConfClaimMarks = 264,

  ConfFin = 265,
  ConfFinSettings = 266,
  ConfTaxes = 257,
}
