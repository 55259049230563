import { RouteRecordRaw } from "vue-router";
import defaultLayout from "@/layouts/side-nav-outer-toolbar.vue";
import { ModuleRoutesNames } from "@/enums/routes/module.routes.names";
import { commonRouteGuard } from "@/routeGuards/common-route-guard";
import { ordersRoutes } from "@/routes/modules/orders-routes";
import { catalogRoutes } from "@/routes/modules/catalog-routes";
import { warehouseRoutes } from "@/routes/modules/warehouse-routes";

export const mainTabRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/pages/main-tab-view.vue"),
    children: [
      {
        path: "profile",
        name: ModuleRoutesNames.Profile,
        component: () => import("@/pages/user/user-profile.vue"),
        beforeEnter: (to, from) => {
          commonRouteGuard(
            to,
            from,
            "",
            ModuleRoutesNames.Profile,
            "userProfile.title",
          );
        },
      },
      ...ordersRoutes,
      ...catalogRoutes,
      ...warehouseRoutes,
    ],
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
    },
  },
];
