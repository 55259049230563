<template>
  <div class="user-info">
    <div class="image-container" v-if="showAvatar">
      <div
        :style="{
          backgroundImage: `url(${user?.UserProfile?.ProfilePicture})`,
        }"
        class="user-image"
      />
    </div>
    <div class="user-name" @click="goToProfileSettings">
      {{ user?.UserProfile.FirstName }}
      {{ user?.UserProfile.LastName }}
    </div>
  </div>
  <dx-list
    ref="userInfoListRef"
    :items="menuItems"
    @item-click="onItemClick"
    :element-attr="{
      class: 'user-info-list',
    }"
  />
</template>

<script setup lang="ts">
import DxList from "devextreme-vue/list";
import { ref } from "vue";
import { User } from "@/interfaces/user/User";
import { useRouter } from "vue-router";
import { ModuleRoutesNames } from "@/enums/routes/module.routes.names";

const router = useRouter();

// eslint-disable-next-line no-undef
withDefaults(
  // eslint-disable-next-line no-undef
  defineProps<{
    showAvatar: boolean;
    menuItems: Array<any>;
    user: User;
  }>(),
  {
    showAvatar: false,
    menuItems: Array,
    user: Object,
  },
);

const userInfoListRef = ref(null);
// eslint-disable-next-line no-undef
const onItemClick = ({ itemData }) => itemData.onClick();

function focusList() {
  // eslint-disable-next-line no-undef
  userInfoListRef.value.instance.focus();
}

function goToProfileSettings() {
  router.push({ name: ModuleRoutesNames.Profile });
}

// eslint-disable-next-line no-undef
defineExpose({ focusList });
</script>
<style scoped lang="scss">
@use "@/variables.scss" as *;

.user-info {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-width: 130px;
  cursor: default;
  padding: var(--list-padding-vertical) var(--list-padding-left);
  border-bottom: 1px solid var(--border-color);

  .user-name {
    font-size: 14px;
    color: var(--base-text-color);
    cursor: pointer;
  }

  .profile-settings-link {
    margin-top: 10px;
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin-right: var(--list-padding-left);
    border: 1px solid var(--border-color);

    .user-image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-color: #fff;
      background-size: cover;
    }
  }
}

.dx-list.user-info-list.dx-scrollable.dx-widget {
  height: auto;
}

:deep(.dx-list-item) .dx-icon {
  vertical-align: middle;
  margin-right: 16px;
}

:deep(.dx-rtl) .dx-list-item .dx-icon {
  margin-right: 0;
  margin-left: 16px;
}
</style>
