import { UserRole } from "@/enums/user/UserRole";
import AuthService from "@/services/auth/AuthService";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import _ from "lodash";

export default function checkRoles(routeRoles: UserRole[]): boolean {
  const authService = new AuthService();
  const allowedUserRolesIds = authService.getUserRoles();

  const isUserAdmin =
    _.intersection(allowedUserRolesIds, [UserRole.IsAdmin]).length > 0;

  const userHasRoles =
    _.intersection(routeRoles, allowedUserRolesIds).length > 0;

  if (isUserAdmin) return true;
  else return userHasRoles;
}
