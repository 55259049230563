import { createRouter, createWebHistory } from "vue-router";
import blankLayout from "@/layouts/single-card.vue";
import AuthService from "@/services/auth/AuthService";
import defaultLayout from "@/layouts/side-nav-outer-toolbar.vue";
import { mainTabRoutes } from "@/routes/main-tab-routes";

const authInfo = new AuthService();

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/pages/common/notfound-page.vue"),
      meta: {
        layout: defaultLayout,
      },
    },
    {
      path: "/login",
      component: () => import("@/components/login/login-form.vue"),
      meta: {
        layout: blankLayout,
      },
    },
    {
      path: "/without-permissions",
      component: () => import("@/pages/common/access-denied.vue"),
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
    },
    ...mainTabRoutes,
  ],
});

router.beforeEach((to, from, next) => {
  if (to.fullPath === "/login" && authInfo.isUserLoggedIn()) {
    next({ path: "/" });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authInfo.isUserLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
