import { RouteRecordRaw } from "vue-router";
import { ModuleRoutesNames } from "@/enums/routes/module.routes.names";
import { commonRouteGuard } from "@/routeGuards/common-route-guard";
import { UserRole } from "@/enums/user/UserRole";
import { crudOrders } from "@/store/orders/crud-orders-store";

export const ordersRoutes: RouteRecordRaw[] = [
  {
    path: "orders",
    name: ModuleRoutesNames.OrdersGrid,
    children: [
      {
        path: "new",
        name: ModuleRoutesNames.NewCrudOrder,
        component: () => import("@/pages/modules/orders/crud-order.vue"),
        beforeEnter: (to, from) => {
          if (!crudOrders.isSelectedCustomer) {
            return false;
          }

          commonRouteGuard(
            to,
            from,
            "",
            ModuleRoutesNames.NewCrudOrder,
            "New Order",
            [UserRole.OrderManagement],
          );
        },
      },
    ],
    component: () => import("@/pages/modules/orders/customer-orders-grid.vue"),
    beforeEnter: (to, from) => {
      commonRouteGuard(
        to,
        from,
        "/orders",
        ModuleRoutesNames.OrdersGrid,
        "sidebar.orders.items.orders",
        [UserRole.OrderManagement],
      );
    },
  },
  {
    path: "orders/:id",
    name: ModuleRoutesNames.CrudOrder,
    component: () => import("@/pages/modules/orders/crud-order.vue"),
    beforeEnter: (to, from) => {
      commonRouteGuard(
        to,
        from,
        "",
        ModuleRoutesNames.CrudOrder,
        "",
        [UserRole.OrderManagement],
        to.params.id as string,
      );
    },
  },
];
