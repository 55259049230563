import { RouteRecordRaw } from "vue-router";
import { ModuleRoutesNames } from "@/enums/routes/module.routes.names";
import { commonRouteGuard } from "@/routeGuards/common-route-guard";

export const catalogRoutes: RouteRecordRaw[] = [
  {
    path: "articles",
    name: ModuleRoutesNames.ArticleGrid,
    component: () => import("@/pages/modules/catalog/article-grid.vue"),
    beforeEnter: (to, from) => {
      commonRouteGuard(
        to,
        from,
        "/articles",
        ModuleRoutesNames.ArticleGrid,
        "sidebar.catalog.items.catalog",
      );
    },
  },
];
