import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ba5bd90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "root" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass($setup.cssClasses)
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.meta.layout), {
        title: "raxwel ERP",
        "is-x-small": $setup.screenInfo.isXSmall,
        "is-large": $setup.screenInfo.isLarge
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_view)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["is-x-small", "is-large"]))
    ], 2 /* CLASS */)
  ]))
}