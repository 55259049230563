import { nextTick } from "vue";
import { createI18n } from "vue-i18n";

let i18n: any;

export const SUPPORT_LOCALES = ["en", "de"];

export function setI18nLanguage(locale: string) {
  loadLocaleMessages(locale);

  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }

  document.querySelector("html")?.setAttribute("lang", locale);
  localStorage.setItem("lang", locale);
}

export async function loadLocaleMessages(locale: string) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  );

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

export default function setupI18n() {
  if (!i18n) {
    const locale = getLocale();

    i18n = createI18n({
      globalInjection: true,
      legacy: false,
      locale: locale,
      fallbackLocale: "en",
      datetimeFormats: {
        en: {
          short: {
            month: "numeric",
            year: "numeric",
            day: "numeric",
          },
          long: {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          },
        },
        de: {
          short: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          long: {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          },
        },
      },
    });

    setI18nLanguage(locale);
  }
  return i18n;
}

function getLocale(): string {
  const locale = localStorage.getItem("lang");

  if (!locale) {
    const browserLocale = navigator.language.split("-")[0];

    if (SUPPORT_LOCALES.includes(browserLocale)) {
      return browserLocale;
    } else {
      return "en";
    }
  } else {
    return locale;
  }
}
