import { createApp } from "vue";
import { AppInfo, appInfoInjectKey } from "@/types/app-info";
import { themeService } from "./theme/theme-service";
import App from "./App.vue";
import { router } from "./router";
import i18n from "./i18n";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "devexpress-gantt/dist/dx-gantt.css";
import "./styles.scss";

themeService.setAppTheme();

const app = createApp(App);

app.use(router);
app.use(i18n());
app.provide<AppInfo>(appInfoInjectKey, {
  title: "raxwel ERP",
});

app.mount("#app");
