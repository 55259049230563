import { UserRole } from "@/enums/user/UserRole";
import checkRoles from "@/utils/roles-helper";
import { missingRoles, store } from "@/store";
import { router } from "@/router";

export function commonRouteGuard(
  to: any,
  from: any,
  redirectUrl: string,
  routeName: string,
  localization: string,
  roles?: UserRole[],
  paramsId?: string,
) {
  if (roles) {
    if (checkRoles(roles)) {
      if (
        redirectUrl.trim().length === 0
          ? true
          : checkIfFromRedirect(from.fullPath, redirectUrl)
      ) {
        const tab = {
          localization: localization,
          path: routeName,
          paramsId: paramsId,
        };

        store.addTabs(tab, false, false);
      }
    } else {
      missingRoles.addReplaceMissingRoles(["test1", "test2"]);
      router.push({ path: "/without-permissions" });
    }
  } else {
    if (
      redirectUrl.trim().length === 0
        ? true
        : checkIfFromRedirect(from.fullPath, redirectUrl)
    ) {
      const tab = {
        localization: localization,
        path: routeName,
        paramsId: paramsId,
      };

      store.addTabs(tab, false, false);
    }
  }
}

function checkIfFromRedirect(fullPath: string, redirectUrl: string): boolean {
  return fullPath.length <= 1 || fullPath === `/login?redirect=${redirectUrl}`;
}
