import { reactive } from "vue";
import { Tab } from "@/interfaces/Tab";
import { router } from "@/router";
import { crudOrders } from "@/store/orders/crud-orders-store";
import { ModuleRoutesNames } from "@/enums/routes/module.routes.names";

export const store = reactive({
  count: 0,
  tabs: [] as Array<Tab>,
  tabIndex: 0,
  isTabNotNeeded: false,

  setTabNoNeeded(value: boolean) {
    this.isTabNotNeeded = value;
  },

  addTabs(tab: any, rightClick: boolean, pushRoute = true) {
    if (this.isTabNotNeeded) {
      this.isTabNotNeeded = false;
      return;
    }
    const newTab: Tab = {
      component: Math.random().toString(36).substring(2),
      routeName: tab.path,
      title: tab.localization,
      routeParameters: tab.paramsId,
    };
    if (rightClick) {
      this.tabs = [...this.tabs, newTab];
      this.tabIndex = this.tabs.length - 1;
    } else {
      if (
        this.tabs.some(
          (t) =>
            `${t.routeName}+${t.routeParameters ?? ""}` ===
            `${newTab.routeName}+${newTab.routeParameters ?? ""}`,
        )
      ) {
        this.tabIndex = this.tabs.findIndex(
          (t) =>
            `${t.routeName}+${t.routeParameters ?? ""}` ===
            `${newTab.routeName}+${newTab.routeParameters ?? ""}`,
        );
      } else {
        this.tabs = [...this.tabs, newTab];
        this.tabIndex = this.tabs.length - 1;
      }
    }

    if (pushRoute) {
      router.push({ name: newTab.routeName });
    }
  },
  changeTabTitle(newTitle: string, orderId: string) {
    const tab = this.tabs.find(
      (t) =>
        t.routeName === ModuleRoutesNames.CrudOrder ||
        t.routeName === ModuleRoutesNames.NewCrudOrder,
    );
    tab!.title = newTitle;
    tab!.orderId = orderId;

    window.document.title = newTitle;
  },
  removeTab(tab: Tab) {
    if (tab.orderId) {
      crudOrders.removeTabsIds(tab.orderId);
    }
    if (
      tab.routeName === ModuleRoutesNames.CrudOrder ||
      tab.routeName === ModuleRoutesNames.NewCrudOrder
    ) {
      crudOrders.isSelectedCustomer = false;
    }

    if (this.tabs.length <= 1) {
      this.tabs = [];
      router.push({ path: "/" });
    } else {
      this.tabs = this.tabs.filter((t) => t !== tab);
      this.tabIndex = this.tabs.length - 1;
    }
  },
  removeAllTabs() {
    this.tabs = [];
  },
  onDrop(reorderedTabs: Tab[]) {
    this.tabs = reorderedTabs;
  },
});

export const missingRoles = reactive({
  roles: [] as string[],
  addReplaceMissingRoles(missingRoles: string[]) {
    this.roles = missingRoles;
  },
});
