import { renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06d36133"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dx-swatch-additional side-navigation-menu" }
const _hoisted_2 = { class: "menu-container theme-dependent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["DxTreeView"], {
        id: "treeview",
        ref: "treeViewRef",
        items: $setup.computedItems,
        "key-expr": "path",
        "selection-mode": "single",
        "focus-state-enabled": false,
        onItemClick: $setup.itemClick,
        "expand-event": "click",
        width: "100%",
        onItemContextMenu: $setup.treeViewItemContextMenu
      }, null, 8 /* PROPS */, ["items"])
    ]),
    _createVNode($setup["DxContextMenu"], {
      ref: "contextMenuRef",
      "data-source": $setup.contextMenuItemsComputed,
      width: 200,
      target: "#treeview .dx-treeview-item",
      onShowing: $setup.menuShowing,
      onItemClick: $setup.itemRightClick
    }, null, 8 /* PROPS */, ["data-source"]),
    _createVNode($setup["AppFooter"])
  ]))
}