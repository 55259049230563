import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4086aae8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-nav-outer-toolbar" }
const _hoisted_2 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AppHeader"], {
      class: "layout-header",
      "menu-toggle-enabled": true,
      "toggle-menu-func": $setup.toggleMenu,
      title: $props.title
    }, null, 8 /* PROPS */, ["title"]),
    _createVNode($setup["DxDrawer"], {
      class: "layout-body",
      position: "before",
      template: "menuTemplate",
      opened: $setup.menuOpened,
      "onUpdate:opened": _cache[0] || (_cache[0] = ($event: any) => (($setup.menuOpened) = $event)),
      "opened-state-mode": $setup.drawerOptions.menuMode,
      "reveal-mode": $setup.drawerOptions.menuRevealMode,
      "min-size": $setup.drawerOptions.minMenuSize,
      "max-size": $setup.drawerOptions.maxMenuSize,
      shading: $setup.drawerOptions.shaderEnabled,
      "close-on-outside-click": $setup.drawerOptions.closeOnOutsideClick
    }, {
      menuTemplate: _withCtx(() => [
        _createVNode($setup["SideNavigationMenu"], {
          "compact-mode": !$setup.menuOpened,
          onClick: $setup.handleSideBarClick
        }, null, 8 /* PROPS */, ["compact-mode"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["opened", "opened-state-mode", "reveal-mode", "min-size", "max-size", "shading", "close-on-outside-click"])
  ]))
}