import { UserRole } from "@/enums/user/UserRole";
import { ModuleRoutesNames } from "@/enums/routes/module.routes.names";

export const navigation = [
  {
    text: "",
    localization: "sidebar.catalog.title",
    icon: "fa fa-grip",
    isExpanded: true,
    roles: [UserRole.Catalogue],
    items: [
      {
        text: "Catalog",
        localization: "sidebar.catalog.items.catalog",
        icon: "fa fa-boxes-stacked",
        hasItems: false,
        path: ModuleRoutesNames.ArticleGrid,
        roles: [UserRole.Articles],
      },
    ],
  },
  {
    text: "",
    localization: "sidebar.orders.title",
    icon: "fa fa-list-check",
    isExpanded: true,
    roles: [UserRole.OrderManagement],
    items: [
      {
        text: "Orders",
        localization: "sidebar.orders.items.orders",
        icon: "fa fa-receipt",
        hasItems: false,
        path: ModuleRoutesNames.OrdersGrid,
        roles: [UserRole.CustomerOrders],
      },
    ],
  },
  {
    text: "",
    localization: "sidebar.warehouse.title",
    icon: "fa fa-warehouse",
    isExpanded: true,
    roles: [UserRole.StockManagement],
    items: [
      {
        text: "Article Stocks",
        localization: "sidebar.warehouse.items.warehouse",
        icon: "fa fa-cubes",
        hasItems: false,
        path: ModuleRoutesNames.InventoryGrid,
        roles: [UserRole.StockItems],
      },
    ],
  },
];
