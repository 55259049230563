<template>
  <div class="auth-card">
    <div class="dx-card content">
      <div class="header">
        <img
          class="logo"
          src="../../assets/erp-icon.svg"
          width="100"
          alt="logo"
        />
        <div class="title">
          {{ props.title }}
        </div>
        <div class="description">
          {{ props.description }}
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line no-undef
const props = defineProps<{
  title?: string;
  description?: string;
}>();
</script>

<style scoped lang="scss">
@use "@/variables" as *;

.auth-card {
  display: flex;
  height: 100%;

  :deep(.dx-button-text) {
    text-transform: none;
  }

  .dx-card {
    margin: auto auto;
    padding: 24px;
    flex-grow: 0;
    max-width: 360px;
    border-radius: 8px;

    &.content {
      height: auto;
      min-width: 350px;
    }

    .screen-x-small & {
      width: 100%;
      height: 100%;
      max-width: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        margin-bottom: 20px;
      }

      .title {
        color: var(--base-text-color);
        text-align: center;
        line-height: 24px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: var(--base-text-color-alpha);
        line-height: 16px;
        font-size: 12px;
        margin-top: 32px;
        text-align: center;
      }
    }
  }
}
</style>
