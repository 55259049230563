import { HttpClientBaseMehod } from "@/enums/api/http-base-method";
import { HttpClientBaseHeader } from "@/interfaces/api/HttpClientHeader";
import { HttpClientBaseStatusCode } from "@/interfaces/api/HttpClientStatusCode";
import { HttpStatusCode } from "@/enums/api/http-status-code";
import notify from "devextreme/ui/notify";
import i18n from "@/i18n";

export class HttpClientBase {
  private resp: any;
  private respBody: any;
  private base_url: string;

  constructor() {
    this.base_url = process.env.VUE_APP_BASE_URL;
  }

  async createRequest(
    url: string,
    method: HttpClientBaseMehod,
    httpHeader?: HttpClientBaseHeader,
    requestData?: any,
    isNotStringified?: boolean,
    isFileDownload?: boolean,
  ): Promise<this> {
    const requestInit: RequestInit = {
      method,
    };

    if (httpHeader) {
      requestInit.headers = httpHeader as any;
    }

    if (requestData) {
      if (isNotStringified) {
        requestInit.body = requestData;
      } else {
        requestInit.body = JSON.stringify(requestData);
      }
    }

    this.resp = await fetch(`${this.base_url}/${url}`, requestInit);

    if (
      method === HttpClientBaseMehod.PATCH ||
      method === HttpClientBaseMehod.DELETE
    ) {
      this.respBody = {};
      return this;
    }

    if (isFileDownload) {
      this.respBody = await this.resp.blob();
    } else {
      this.respBody = await this.resp.json();
    }

    const statusCode = this.statusCode();

    if (statusCode.statusCode >= 400) {
      const { t } = i18n().global;
      notify(
        t(this.respBody.TranslationKey ?? this.respBody.Message),
        //i18n().t(this.respBody.TranslationKey ?? this.respBody.Message),
        "error",
        3000,
      );
    }

    return this;
  }

  statusCode(): HttpClientBaseStatusCode {
    const statusCode = this.resp.status as HttpStatusCode;
    const statusText = HttpStatusCode[
      this.resp.status
    ] as unknown as HttpStatusCode;

    return {
      statusCode,
      statusText,
    };
  }

  isOk(): boolean {
    return this.resp.ok;
  }

  responseByKey<T>(key: string | null = null): T {
    if (key) {
      return this.respBody[key];
    } else {
      return this.respBody;
    }
  }

  responseData<T>(): T {
    return this.respBody.data;
  }
}
