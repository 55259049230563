<template>
  <div class="dx-swatch-additional side-navigation-menu">
    <slot />
    <div class="menu-container theme-dependent">
      <dx-tree-view
        id="treeview"
        ref="treeViewRef"
        :items="computedItems"
        key-expr="path"
        selection-mode="single"
        :focus-state-enabled="false"
        @item-click="itemClick"
        expand-event="click"
        width="100%"
        @item-context-menu="treeViewItemContextMenu"
      />
    </div>
    <DxContextMenu
      ref="contextMenuRef"
      :data-source="contextMenuItemsComputed"
      :width="200"
      target="#treeview .dx-treeview-item"
      @showing="menuShowing"
      @item-click="itemRightClick"
    />
    <app-footer />
  </div>
</template>

<script setup lang="ts">
import DxTreeView from "devextreme-vue/tree-view";
import { sizes } from "@/utils/media-query";
import { computed, ref } from "vue";
import { navigation } from "@/app-navigation";
import AppFooter from "@/components/common/layout/app-footer.vue";
import { store } from "@/store";
import { useI18n } from "vue-i18n";
import DxContextMenu, { DxContextMenuTypes } from "devextreme-vue/context-menu";
import checkRoles from "@/utils/roles-helper";

const { t } = useI18n();

const isLargeScreen = sizes()["screen-large"];
const selectedTreeItem = ref();

const contextMenuItemsComputed = computed(() => {
  return [{ text: t("common.openInNewTab") }];
});

const computedItems = computed(() => {
  return navigation
    .filter(
      (item) =>
        checkRoles(item.roles) &&
        item.items.some((nestedItem) => checkRoles(nestedItem.roles)),
    )
    .map((item) => {
      item.text = t(item.localization);

      item.items = item.items.filter((nestedItem) =>
        checkRoles(nestedItem.roles),
      );

      item.items.map((i) => {
        i.text = t(i.localization);
      });

      return { ...item, expanded: isLargeScreen };
    });
});

function menuShowing(e: any) {
  if (!selectedTreeItem.value.path) {
    e.cancel = true;
  }
}

function treeViewItemContextMenu(e: any) {
  selectedTreeItem.value = e.itemData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function itemRightClick({ itemData }: DxContextMenuTypes.ItemClickEvent) {
  store.addTabs(selectedTreeItem.value, true);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function itemClick({ itemData }) {
  window.document.title = itemData.text;
  if (itemData.path) {
    store.addTabs(itemData, false);
  }
}
</script>

<style scoped lang="scss">
@use "@/variables" as *;
.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  &:deep(.menu-container) {
    min-height: 100%;
    display: flex;
    flex: 1;
    background-color: var(--side-panel-background);
    padding-top: var(--toolbar-vertical-padding);
    padding-bottom: var(--footer-height);
    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##
      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        flex-direction: row-reverse;
        border-radius: 0;

        .dx-icon {
          width: var(--side-panel-min-width) !important;
          margin: 0 !important;
        }
      }
      // ##
      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }
      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }
      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      // ##
      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          font-weight: bold;
          border-bottom: 1px solid var(--border-color);
        }
        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 0 0 var(--side-panel-min-width);
        }
      }
      // ##
    }
    // ##
  }
}
</style>
