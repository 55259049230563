import { RouteRecordRaw } from "vue-router";
import { ModuleRoutesNames } from "@/enums/routes/module.routes.names";
import { commonRouteGuard } from "@/routeGuards/common-route-guard";
import { UserRole } from "@/enums/user/UserRole";

export const warehouseRoutes: RouteRecordRaw[] = [
  {
    path: "inventory",
    name: ModuleRoutesNames.InventoryGrid,
    component: () => import("@/pages/modules/warehouse/inventory-grid.vue"),
    beforeEnter: (to, from) => {
      commonRouteGuard(
        to,
        from,
        "/inventory",
        ModuleRoutesNames.InventoryGrid,
        "sidebar.warehouse.items.warehouse",
        [UserRole.OrderManagement],
      );
    },
  },
];
