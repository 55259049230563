import { reactive } from "vue";
import { SelectedCustomer } from "@/interfaces/modules/customer-orders/SelectedCustomer";
import { Address } from "@/interfaces/modules/customer-orders/Address";

export const crudOrders = reactive({
  existingTabsIds: [] as any[],
  isSelectedCustomer: false,
  selectedAccountType: "",
  selectedAcountIcon: "",
  selectedAaccountName: "",
  selectedAccountId: 0,
  selectedOrderType: "",
  selectedCustomer: {} as SelectedCustomer,
  address: {} as Address,

  addTabsIds(tabId: string) {
    this.existingTabsIds = [...this.existingTabsIds, tabId];
  },

  removeTabsIds(id: string) {
    this.existingTabsIds = this.existingTabsIds.filter((i) => i !== id);
  },

  assignSelectedOrderType(orderType: string) {
    this.selectedOrderType = orderType;
  },

  assignSelectedAccountType(
    accountType: string,
    accountIcon: string,
    accountId: number,
    accountName: string,
  ) {
    this.selectedAccountType = accountType;
    this.selectedAcountIcon = accountIcon;
    this.selectedAccountId = accountId;
    this.selectedAaccountName = accountName;
  },

  assignSelectedCustomer(customer: any, address: any) {
    this.selectedCustomer = customer;
    this.address = address;
    this.isSelectedCustomer = true;
  },
});
