<template>
  <header>
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc" />
        </template>
      </dx-item>

      <dx-item location="before" css-class="header-title dx-toolbar-label">
        <img class="logo" src="../../../assets/erp-logo.png" width="100" />
      </dx-item>
      <dx-item location="after" locate-in-menu="never">
        <theme-switcher />
      </dx-item>
      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <template #default>
          <div>
            <user-panel
              :user="user"
              :menu-items="userMenuItems"
              menu-mode="context"
            />
          </div>
        </template>
      </dx-item>
      <template #menuUserItem>
        <user-panel :user="user" :menu-items="userMenuItems" menu-mode="list" />
      </template>
    </dx-toolbar>
  </header>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { computed, onBeforeMount, ref } from "vue";
import { DxButton, DxButtonTypes } from "devextreme-vue/button";
import { DxItem, DxToolbar } from "devextreme-vue/toolbar";
import ThemeSwitcher from "./theme-switcher.vue";
import UserPanel from "@/components/user/user-panel.vue";
import { useI18n } from "vue-i18n";
import AuthService from "@/services/auth/AuthService";
import { User } from "@/interfaces/user/User";
import {
  formatMessage as dxFormatMessage,
  loadMessages,
  locale as dxLocale,
} from "devextreme/localization";
import { store } from "@/store";

const localeDevExtreme: any = getLocale();
// eslint-disable-next-line @typescript-eslint/no-var-requires
const deMessages = require("devextreme/localization/messages/de.json");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const enMessages = require("devextreme/localization/messages/en.json");

const formatMessage = ref((msg: any) => msg);

onBeforeMount(() => {
  dxLocale(localeDevExtreme);
  initMessages();
});

function getLocale(): any {
  const storageLocale = localStorage.getItem("lang");

  if (!storageLocale) {
    return navigator.language.split("-")[0];
  } else {
    return storageLocale;
  }
}

function initMessages() {
  loadMessages(deMessages);
  loadMessages(enMessages);
  formatMessage.value = dxFormatMessage;
}

const { t } = useI18n();

const router = useRouter();

const user = ref<User>();

const authService = new AuthService();

// eslint-disable-next-line no-undef
defineProps<{
  menuToggleEnabled: boolean;
  title: string;
  toggleMenuFunc: (e: DxButtonTypes.ClickEvent) => void;
}>();

user.value = authService.getUser();

function onLogoutClick() {
  store.removeAllTabs();
  authService.logout();
  router.push({ path: "/login" });
}

const userMenuItems = computed(() => {
  const menuItems = [
    {
      text: t("common.buttons.logout"),
      icon: "runner",
      onClick: onLogoutClick,
    },
  ];

  return menuItems;
});
</script>
<style scoped lang="scss">
@use "@/variables" as *;

header {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.05),
    0 0 4px rgba(0, 0, 0, 0.15);
  padding: var(--header-toolbar-vertical-padding) 0;
  background-color: var(--base-bg);

  .header-toolbar {
    padding-right: var(--content-padding);

    :deep(.global-search-box) {
      padding-right: 11px;
    }

    .logo {
      margin-left: 10px;
    }

    .messages {
      position: relative;

      .dx-badge {
        position: absolute;
        background-color: red;
        color: white;
        right: -10%;
        top: -10%;
        font-size: 12px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
      }
    }

    :deep(.dx-toolbar-item.menu-button) {
      width: var(--side-panel-min-width);
      text-align: center;
      padding: 0;
    }
  }
}
</style>
