import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["DxScrollView"], {
    height: "100%",
    width: "100%",
    class: "view-wrapper-scroll single-card"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["CardAuth"], {
        title: $setup.title,
        description: $setup.description
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["title", "description"])
    ]),
    _: 3 /* FORWARDED */
  }))
}